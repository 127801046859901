.blogImage {
    position: relative;
    width: 180px;
}

.aicareLogo {
    top: -10px;
    width: 100px;
}

.blogDate {
    font-size: 18px;
    color: #0071bb;
}

.distralLogo {
    width: 450px;
}