.processImage {
    position: relative;
    top: 30px;
    margin-bottom: 80px;
}

.gitlabLogoLarge {
    position: relative;
    width: 200px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.carouselImage {
    max-height: 400px;
}