.title {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 200;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.proskiveLogo {
  max-width: 100%;
  margin-bottom: -50px;
}

@media only screen and (max-width: 768px) {
  .proskiveLogo {
    max-width: 90%;
    margin-bottom: -30px;
  }
}

.instLogo {
  position: relative;
  max-width: 200px;
  margin-right: 70px;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .instLogo {
    max-width: 70%;
    margin-top: 20px;
  }
}

.imiLogo {
  margin-top: 30px;
  margin-right: 30px;
  max-width: 250px;
}

@media only screen and (max-width: 768px) {
  .imiLogo {
    max-width: 80%;
    margin-top: 50px;
  }
}

.downButton {
    top: 80px;
    margin-left: -50px;
    cursor: pointer;
    position: relative;
    left: 50%;
    padding-bottom: 50px;
}

@-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }

  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  